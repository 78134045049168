export const roles = {
    provider: {
        exclude_fields: ['provider_id']
    },
    audience: {
        exclude_fields: ['provider_id', 'default_status_id', 'fpd']
    },
    collector: {
        exclude_fields: ['provider_id']
    },
    supervisor: {
        exclude_fields: ['provider_id']
    },
};