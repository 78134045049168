import React from 'react'
import Resource from '../../Resources/Resource'

const notificationView = (props) => {

    const resource = {
        title: 'Visualización de la Notificación',
        notification_id: props.match.params.id,
        url: '/notifications/',
        fields: [
            {
                name:'body',
                traduction:'Mensaje',
                component: {
                    name: 'StringToHtml',
                    props: {
                        traduction:'Mensaje'
                    }
                }
            },
        ]
    };

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.notification_id} fields={resource.fields}/>
    );
}

export default notificationView;