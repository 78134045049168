import React from 'react'
import Resource from '../Resources/Resource'

const ProviderWebhookView = (props) => {

    const resource = {
        title: 'Webhook',
        id: props.match.params.id,
        url: '/provider_webhooks/',
        fields: [
            { name:'method', traduction:'Método'},
            { name:'url', traduction:'Url'},
            { name:'body', traduction:'JSON'},
            { name:'active', traduction:'Activo', checkbox: true},
            {
                name: 'provider_webhook_action',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Acción'}
                ],
            },
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.id} fields={resource.fields}/>
    );

}

export default ProviderWebhookView;