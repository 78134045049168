import React from 'react'
import ReportGeneralExport from "./ReportGeneralExport";

const reportGeneralExportEdit = (props) => {

    const resource = {
        title: 'Editar Reporte General',
        resource_id: props.match.params.id,
        url: '/export_reports/',
        action: 'edit',
        task: true
    };

    return (
        <div>
            <ReportGeneralExport {...resource}/>

        </div>
    );

};

export default reportGeneralExportEdit;