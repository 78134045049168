import React, {useEffect, useRef} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import { Messages } from 'primereact/messages';

const ClientPaymentsNotProcessed = ({selectedLoan}) => {

    const message = useRef(null);

    useEffect(() => {
        let apiService = new ApiLoan();

        let url = '/payments/not_processed/';
        apiService.getResource({
            url: url,
            resource_id: selectedLoan
        }).then(response => {
            let has_payments_not_processed = response.data.message;
            if (has_payments_not_processed){
                message.current.show([
                    { severity: 'info', summary: 'Pagos: ', detail: ' Hay operaciones aún no procesadas', sticky: true },
                ]);
            }
        });
    }, [selectedLoan]);

    return (
        <Messages ref={message} />
    );

}

export default ClientPaymentsNotProcessed;