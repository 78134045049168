import React, {useEffect, useState} from "react";
import ApiLoan from "../../service/ApiLoanService";

import Resources from "../Resources/Resources";

const TransactionRequests = props => {
    const [loading, setLoading] = useState(true);
    const [gatewayId, setGatewayId] = useState(undefined);
    const [resource, setResource] = useState(null);

    useEffect(() => {
        let mounted = true;
        let apiService = new ApiLoan();
        if (mounted){
            apiService.getResources({
                url: '/payment_methods',
                filters: [
                    {"name": "slug", "op": "eq", "val": 'coelsa'}
                ],
            }).then(resp => {
                setGatewayId(resp.data.objects[0].id);
            });
        }
        return () => mounted = false;
    },[setGatewayId]);

    useEffect(() => {
        let mounted = true;
        if (gatewayId === undefined){
            return;
        }

        const fields = {
            title: 'Liquidaciones',
            url: '/coelsa/transaction_requests',
            colsHeader: [
                { field: 'created', header: 'Fecha', date: true},
                { field: 'amount', header: 'Monto Total' },
                { field: 'discount_amount', header: 'Descuento'},
                { field: 'net_amount', header: 'Monto Neto'},
                { field: 'transaction_status.description', header: 'Nombre'},
                { field: 'user.name', header: 'Cliente' },
                { field: 'action', header: 'Acciones' }
            ],
            action:{
            },
            customActions: [
                { field: 'transaction_request_actions'},
                { field: 'get_transaction_logs_csv', uri: '/files/'}
            ],
            params: {
                filters: [
                    {
                        name: 'gateway_id',
                        op: 'eq',
                        val: gatewayId
                    },
                ],
                order_by: {
                    field: 'created',
                    direction: 'desc'
                }
            },
            urlTable: [
                '/coelsa/transaction_requests',
            ]
        }
        if (mounted){
            setResource(fields)
            setLoading(false);
        }

        return () => mounted = false;

    }, [gatewayId]);

    if (loading){
        // add spinner
        return null;
    } else {
        return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                           customActions={resource.customActions} params={resource.params}
                           action={resource.action} urlTable={resource.urlTable}/>);
    }

}

export default TransactionRequests;