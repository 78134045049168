import React, {Fragment} from 'react';

import ClientPaymentTable from './ClientPaymentTable';

const ClientPaymentTables = ({loans, selectedLoan}) => {
    const filteredLoans = loans.filter((loan) => {
        return loan.id === selectedLoan ? loan : null;
    })
    const loanTabs = filteredLoans.map((loan, index) => {
        return (<ClientPaymentTable key={index} loan={loan}/>);
    });
    return (<Fragment>{loanTabs}</Fragment>);
}

export default ClientPaymentTables;