import React from 'react';
import Resources from '../Resources/Resources';

const rates = () => {
    const resource = {
        title: 'Tarifas',
        url: '/rates',
        colsHeader: [
            { field: 'channel.description', header: 'Canal'},
            { field: 'price', header: 'Precio', format:'money'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/rate/',
            edit: '/edit'
        },
        urlTable: [
            '/rates',
            '/rates/new',
            '^\\/rate\\/\\d+$',
            '^\\/rate\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default rates;