import React from 'react'
import Resource from '../../Resources/Resource'

const channelView = (props) => {

    const resource = {
        title: 'Canal',
        channel_id: props.match.params.id,
        url: '/channels/',
        fields: [
            { name:'name', traduction:'Nombre'},
            { name:'description', traduction:'Description'}
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.channel_id} fields={resource.fields} />
        </div>
    );

}

export default channelView;