import React, {Fragment, useRef, useEffect} from 'react';
import Resources from '../Resources/Resources';
import { Toast } from 'primereact/toast';

const ReportGeneralExportDataTable = (props) => {

    let resource = {
        title: 'Tareas de Reporte General',
        url: '/export_reports',
        colsHeader: [
            { field: 'day_range', header: 'Filtro de días'},
            { field: 'enabled', header: 'Habilitado', checkbox: true },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/reports/export/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/export_reports',
            '/export_reports/new',
            '^\\/export_report\\/\\d+$',
            '^\\/export_report\\/\\d+\\/edit$'
        ]
    };

    useEffect(() => {
        if (props.location.state !== undefined && props.location.state !== null){
            showInfoMessage(props.location.state.message);
        }
    },[props.location.state]);

    const toast = useRef(null);

    const showInfoMessage = message => {
        toast.current.show({severity: 'success', summary: 'Reporte General', detail: message});
    };

    let {colsHeader} = resource;

    let updatedResource = {...resource, colsHeader: colsHeader};


    return (
        <Fragment>
            <Toast ref={toast} />
            <Resources title={updatedResource.title} url={updatedResource.url} colsHeader={updatedResource.colsHeader}
                       action={updatedResource.action} urlTable={updatedResource.urlTable} />
        </Fragment>

   );
};

export default ReportGeneralExportDataTable;

