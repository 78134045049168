import React from 'react'
import {Link} from 'react-router-dom'
import Resource from '../../Resources/Resource'
import {Button} from "primereact/button";

const CustomTagView = (props) => {

    const resource = {
        title: 'Tag',
        custom_tag_id: props.match.params.id,
        url: '/custom_tags/',
        fields: [
            { name:'slug', traduction:'Slug'},
            { name:'name', traduction:'Name'},
            { name:'description',traduction:'Descripción'},
            { name:'active', traduction:'Activo', checkbox: true},
            { name:'fixed', traduction:'Fijo', checkbox: true},
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.custom_tag_id} fields={resource.fields} />
            <Link to={'/custom_tags/'+ resource.custom_tag_id +'/edit'}>
                <Button icon="pi pi-pencil" label="Editar"  />
            </Link>
        </div>
    );

}

export default CustomTagView;