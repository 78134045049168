import React from 'react';

const SearchItem = ({fields, element, onClick}) => {
    const itemContent = fields.map((field, index) => {
        return (<div key={index}>{element[field]}</div>)
    });
    return (
        <li>
            <div className="search-item-view" onClick={onClick}>
                {itemContent}
            </div>
        </li>
    );
} 

export default SearchItem; 