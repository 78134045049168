import React from 'react';
import moment from 'moment';
import 'moment/locale/es';

import { formatMoney } from '../../utils/utils';

const BLUE_WALLET = "assets/layout/images/wallet-blue.png";
const GREEN_WALLET = "assets/layout/images/wallet-green.png";
const GREY_WALLET = "assets/layout/images/wallet-grey.png";

const TransactionItem = ({description, amount, created}) => {
    let isNegative = false;
    let formattedValueWithSign = null;
    let walletIcon = GREY_WALLET;

    if(amount > 0){
        walletIcon = GREEN_WALLET;
    }else{
        walletIcon = BLUE_WALLET;
    }

    if(amount < 0){
        amount = -amount
        isNegative = true
    }

    const formattedValue = formatMoney(amount, 2, ',', '.', '$');

    formattedValueWithSign = formattedValue;

    if(isNegative){
        formattedValueWithSign = '-' + formattedValue;
    }

    return (
    <li>
        <div className="transaction-view">
            <div className="transaction-description">
            <img src={walletIcon} alt="transaction-type" />
            <div>{description}</div>
            </div>
            <div className="transaction-amount">
                <div className="transaction-number">
                    {formattedValueWithSign}
                </div>
                <div className="transaction-date">
                    {moment(created).locale('es').format('D [de] MMMM')}
                </div>
            </div>
        </div>
    </li>)
} 

export default TransactionItem; 