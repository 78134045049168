import React from 'react'
import Resource from '../../Resources/Resource'

const rateView = (props) => {

    const resource = {
        title: 'Tarifa',
        rate_id: props.match.params.id,
        url: '/rates/',
        fields: [
            {
                name: 'channel',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Canal'}
                ],
            },
            { name:'price',traduction:'Precio', format:'money' }
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.rate_id} fields={resource.fields} />
        </div>
    );

}

export default rateView;