import React from 'react';

import Resources from '../Resources/Resources';


const Clients = () => {
    const resource = {
        title: 'Lista de Deudores',
        url: '/debtors/transactions',
        colsHeader: [
            { field: 'lastname', header: 'Apellido', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'firstname', header: 'Nombre', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'document_number', header: 'Identificador', sortable: true, filter: true },
            { field: 'default_status_description',
                header: 'Estado', 
                filter: true,
                filter_dropdown: {
                    url: '/default_statuses/filter',
                    value: 'description',
                    label:'description',
                    default: 'Todos',
                    is_relation: false
                },
                relation: 'default_status_description'
            },
            { field: "custom_tags__name",
				header: "Tags",
				filter: true,
				multiselect_dropdown: {
					params: {
						url: "/custom_tags",
						filters: [
							{
								name: "active",
								op: "equals",
								val: true
							}
						],
                        page: 1
					},
					value: "name",
					label: "name",
                    selectedItemsLabel: '{0} tags seleccionados',
				},
				relation: "custom_tags__name",
				parent_field: "custom_tags"
			},
            { field: 'total_debt', header: 'Deuda Total', format:'money' },
            { field: 'total_paid', header: 'Total Pagado', format:'money' },
            { field: 'expired_debt', header: 'Deuda Vencida', format:'money' },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/debtor/',
            edit: '/edit'
        },
        customTableHeaderButtons: [
            {
                label: 'Deudor', icon: 'pi pi-user-plus', command: () => { window.location = '#/debtors/new' }
            },
            {
                label: 'Operación', icon: 'pi pi-dollar', command: () => { window.location = '#/loans/new' }
            },
        ],
        customActions: [
            { field: 'jwt', uri: '/loans/debtor/', icon: 'pi pi-trash' },
            { field: 'contacts'},
            { field: 'short_url'},
            { field: 'google_link'}
        ],
        urlTable: [
            '/debtors',
            '/debtors/new',
            '^\\/debtor\\/\\d+$',
            '^\\/debtor\\/\\d+\\/edit$',
            '/loans/new',
            '^\\/loans\\/debtor\\/\\d+$'
        ]
    }

    if (process.env.REACT_APP_PAGE === 'tag-app'){
        resource.customActions.push(
            { field: 'qr_code'},
        )
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       customTableHeaderButtons={resource.customTableHeaderButtons} customActions={resource.customActions}
                       urlTable={resource.urlTable} />);
}

export default Clients;