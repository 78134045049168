import React, {useState, useEffect} from 'react';
import PayOrders from "./PayOrders";

const DebitPayOrders = (props) => {
    const [customTableHeaderButtons, setCustomTableHeaderButtons] = useState(null);
    const [colsHeader, setColsHeader] = useState(null);
    const [deletePayOrder, setDeletePayOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let custom_table_header_buttons = [
            {
                label: 'Cargar por csv', command: () => { window.location = '#/pay_orders/upload' }
            },

            {
                label: 'Individual', command: () => { window.location = '#/pay_orders/debit/single' }
            },
        ];
        setCustomTableHeaderButtons(custom_table_header_buttons);

        let custom_cols_header = [
            { field: 'description', header: "Descripción", sortable: false, filter: false },
        ];
        setColsHeader(custom_cols_header);

        let delete_pay_order = { field: 'cancel_pay_order', uri: '/pay_order/cancel'};
        setDeletePayOrder(delete_pay_order);

        setLoading(false)
    },[]);

    let form = null;
    if (!loading){
        form = <PayOrders desactivateRegenerateOrders={true}
                          customTableHeaderButtons={customTableHeaderButtons}
                          deletePayOrder={deletePayOrder}
                          customColsHeader={colsHeader}/>
    }
    return form;
}

export default DebitPayOrders;