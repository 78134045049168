import React from 'react';
import Resources from '../Resources/Resources';
import {roles} from './User/userConfig';
import {switchRole} from "../../shared/roles";

const users = () => {
    let resource = {
        title: 'Usuarios',
        url: '/users',
        colsHeader: [
            { field: 'name', header: 'Nombre Completo'},
            { field: 'username', header: 'Nombre de Usuario'},
            { field: 'provider.name',
                header: 'Cliente',
                filter: true,
                filter_dropdown: {
                    url: '/clients',
                    value: 'name',
                    label: 'name',
                    default: 'Todos',
                    filters: [
                        {
                            name: "available",
                            op: "eq",
                            val: true
                        }
                    ],
                },
                relation: 'provider__name'
            },
            { field: 'action', header: 'Acciones' }
        ],
        params: {
        },
        action: {
            add: '/new',
            show: '/user/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/users',
            '/users/new',
            '^\\/user\\/\\d+$',
            '^\\/user\\/\\d+\\/edit$'
        ]
    };

    if(roles[switchRole()]){
        let colsHeader = resource.colsHeader.filter(x => {
            return !roles[switchRole()].exclude_fields.includes(x.field)
        });
        resource.colsHeader = colsHeader
    }
    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       params={resource.params} action={resource.action} urlTable={resource.urlTable} />);
}

export default users;