import React from 'react'
import DefaultStatus from "./DefaultStatus";

const defaultStatusEdit = (props) => {

    const resource = {
        title: 'Editar Estado de Deuda',
        url: '/default_statuses/',
        default_status_id: props.match.params.id,
        fields: [
            {
                description: {
                    elementType: 'text',
                    elementConfig: {
                    },
                    value: '',
                    traduction: 'Descripción'
                },
                from_day: {
                    elementType: 'text',
                    elementConfig: {
                        keyfilter: 'pint'
                    },
                    value: '',
                    traduction: 'Desde'
                },
                to_day: {
                    elementType: 'text',
                    elementConfig: {
                        keyfilter: 'pint'
                    },
                    value: '',
                    traduction: 'Hasta'
                },
                severity: {
                    elementType: 'text',
                    elementConfig: {
                        keyfilter: 'pint'
                    },
                    value: '',
                    traduction: 'Gravedad'
                }
            }
        ],
        action: 'edit'

    }

    return (
        <DefaultStatus title={resource.title} url={resource.url} fields={resource.fields} resource_id={resource.default_status_id} action={resource.action}/>
    );



}

export default defaultStatusEdit;