import React from 'react'
import {Link} from 'react-router-dom'
import Resource from '../../Resources/Resource'
import {Button} from "primereact/button";

const EmailSettingView = (props) => {

    const resource = {
        title: 'Configuración de Email',
        email_setting_id: props.match.params.id,
        url: '/email_settings/',
        fields: [
            { name:'mail_server', traduction:'Servidor'},
            { name:'mail_port', traduction:'Puerto'},
            { name:'mail_use_tls',traduction:'TLS', checkbox: true},
            { name:'mail_use_ssl', traduction:'SSL', checkbox: true},
            { name:'mail_username', traduction: 'Usuario'},
            { name:'default_mail_sender', traduction:'email'},
            { name:'reply_to', traduction:'Responder a'}
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.email_setting_id} fields={resource.fields} />
            <Link to={'/email_settings/'+ resource.email_setting_id +'/edit'}>
                <Button icon="pi pi-pencil" label="Editar"  />
            </Link>
        </div>
    );

}

export default EmailSettingView;