import React from 'react'
import Resource from '../../Resources/Resource'

const reportGeneralExportView = (props) => {

    const resource = {
        title: 'Reporte General',
        export_report_id: props.match.params.id,
        url: '/export_reports/',
        fields: [
            {
                name:'frecuency',
                traduction:'Frecuencia',
                component: {
                    name: 'FrecuencyView',
                    props: {}
                }
            },
        ]
    };

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.export_report_id} fields={resource.fields} />
        </div>
    );

};

export default reportGeneralExportView;