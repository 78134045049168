import React  from 'react';
import Resources from '../Resources/Resources';

const defaultStatuses = () => {
    const resource = {
        title: 'Estado de Deuda',
        url: '/default_statuses',
        colsHeader: [
            { field: 'description', header: 'Descripción', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'severity', header: 'Gravedad', sortable: true },
            { field: 'from_day', header: 'Desde', sortable: true },
            { field: 'to_day', header: 'Hasta', sortable: true },
            { field: 'slug', header: 'Slug', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/default-status/',
            edit: '/edit',
        },
        urlTable: [
            '/default-statuses',
            '/default-statuses/new',
            '^\\/default-status\\/\\d+$',
            '^\\/default-status\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default defaultStatuses;
