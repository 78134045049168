import React from 'react';

import ModalForm from '../../UI/ModalForm';

import PayOrdersStatuses from './PayOrdersStatuses';

const ModalPayOrdersStatuses = ({installmentId, linkBody}) => {
    return (<ModalForm title={'Estado de órdenes de pago'} linkBody={linkBody}>
                <PayOrdersStatuses installmentId={installmentId}/>
            </ModalForm>);
}

export default ModalPayOrdersStatuses;