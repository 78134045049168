import React from 'react';

import ModalForm from '../../../UI/ModalForm';
import QrCodeView from "./QrCodeView";

const ModalQrCode = ({client_id}) => {
    return (<ModalForm title={'Código QR'}
                       width={'35vw'}
                       buttonProps={{size: 'xs', className: 'btn-actions btn-actions-default btn-fontawesome', icon: 'qrcode'}}>
        <QrCodeView client_id={client_id}/>
    </ModalForm>);
};

export default ModalQrCode;