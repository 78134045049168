import React, {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ApiLoan from "../../service/ApiLoanService";
import TransactionAmounts from './TransactionAmounts';
import TransactionItem from './TransactionItem';

const TransactionsList = ({parentRef}) => {
    const apiService = new ApiLoan();
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const getTransactions = (page) => {
        return apiService.getResources({
            url: '/transactions',
            page: page,
            order_by: [{"field": "created", "direction": "desc"}],
        }).then(response => {
            const formattedTransactions = response.data.objects.map((transaction) => {
                let description;
                if (transaction.description === 'Retiro de dinero'){
                    description = 'Retiro de Dinero';
                }
                else if (transaction.description === 'Cobro de comisiones'){
                    description = 'Comisión Cobrada';
                } else {
                    description = 'Cliente DNI: '+transaction.payment.client.document_number
                }
              const sanitizedTransaction = {
                description: description,
                amount: transaction.amount,
                created: transaction.created
              }

              return sanitizedTransaction
            })
            setHasMore(response.data.page < response.data.total_pages)
            setTransactions(transactions.concat(formattedTransactions));
            setLoading(false);
        });
    }

    const transactionsList = transactions.map(((transaction, index) => {
        return (<TransactionItem key={index} description={transaction.description} amount={transaction.amount} created={transaction.created}/>)
    }))

    const loadingSpinner = 
    (<div className="spinner-container">
        <img src={"/assets/layout/images/loading.gif"} alt={"Loading Transactions"}/>
    </div>);


    const transactionListWithScroll = (<InfiniteScroll
                                            pageStart={0}
                                            loadMore={getTransactions}
                                            hasMore={hasMore}
                                            useWindow={false}
                                            loader={<div key={'loading'}>Cargando...</div>}>
                                            <TransactionAmounts/>
                                            <div className="card">
                                                <ul className="transaction-list">
                                                {transactionsList}
                                                </ul>
                                            </div>
                                        </InfiniteScroll>)

    return loading ? loadingSpinner : transactionListWithScroll

}

export default TransactionsList;