import React from 'react'
import {Link} from 'react-router-dom'
import Resource from '../../Resources/Resource'
import {Button} from "primereact/button";

const SmsSettingView = (props) => {

    const resource = {
        title: 'Sms',
        sms_setting_id: props.match.params.id,
        url: '/sms_settings/',
        fields: [
            { name:'provider.name', traduction:'Slug'},
            { name:'gateway',traduction:'Descripción'},
            {
                card: { title: 'Configuración' },
                name: 'config',
                traduction: 'Configuración',
                component:{
                    name: 'SmsConfigView',
                    props: {
                        sms_setting_id: props.match.params.id
                    }
                }
            },
            { name:'active', traduction:'Activo', checkbox: true},
        ]
    };

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.sms_setting_id} fields={resource.fields} />
            <Link to={'/sms_setting/'+ resource.sms_setting_id +'/edit'}>
                <Button icon="pi pi-pencil" label="Editar"  />
            </Link>
        </div>
    );

};

export default SmsSettingView;