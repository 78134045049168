import React from 'react';
import { formatMoney } from '../../utils/utils';

const TransactionAmountDigits = ({amount}) => {
    const formattedValue = formatMoney(amount, 2, ',', '.', '$');
    const formattedAmount = formattedValue.split(',');

    return (<div className="total-amount-digits">
                <div className="total-amount-value-integer">{formattedAmount[0]},</div>
                <div className="total-amount-value-decimal">{formattedAmount[1]}</div>
            </div>)
}

export default TransactionAmountDigits;