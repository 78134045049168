import React, {useEffect} from "react";
import Messenger from "./Messenger/index";
import ApiLoan from '../../service/ApiLoanService';
import { useHistory } from "react-router-dom";

const MessengerComponent = (props) => {
    const history = useHistory();
    useEffect(() => {
        let provider_id = localStorage.getItem('provider_id');
        let gateway = localStorage.getItem('gatewayWaba');
        let role = localStorage.getItem('role');
        if ((role === '4' || role === '9') && gateway === 'OPEN-WA'){
            let url = '/clients/';
            const apiLoan = new ApiLoan();
            apiLoan.getResource({
                url: url,
                resource_id: provider_id
            }).then(response => {
                if (response.data.gateway === 'OPEN-WA'){
                    apiLoan.getResources({
                        url: '/info/session/open_wa'
                    }).then((response) => {
                        if(response?.data?.msg){
                            history.push( '/setting_whatsapp' );
                        }
                    })
                }
            });
        }
    },[history]);
    return <Messenger />
}

export default MessengerComponent