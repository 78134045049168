import React from 'react'
import Resource from '../../Resources/Resource'

const providerView = (props) => {

    const resource = {
        title: 'Cliente',
        provider_id: props.match.params.id,
        url: '/clients/',
        fields: [
            { name:'slug', traduction:'Slug'},
            { name:'name',traduction:'Nombre'},
            { name:'address', traduction:'Dirección'},
            { name:'email', traduction:'Email'},
            { name:'phone', traduction:'Teléfono'},
            { name:'cbu', traduction:'CBU'},
            { name:'cuit', traduction:'CUIT'},
            { name:'created', traduction:'Creado'},
            { name:'updated', traduction:'Actualizado'},
            { name:'gateway', traduction:'Gateway'},
            { name: 'available', traduction: 'Habilitado', checkbox: true}
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.provider_id} fields={resource.fields} />
        </div>
    );

}

export default providerView;