import React, { Component } from 'react';
import ClientLoan from './ClientLoan';


class ClientLoans extends Component {

    state = {
        loans: this.props.loans,
        activeLoan: 0
    };

    render(){
        let clientLoansComponent = 'Cargando...';

        if(!this.props.loading){
            const {loans} = this.props;
            const loansTabs = loans.map((loan, index) => {
                return (<ClientLoan loan={loan} key={index}/>);
            });
            clientLoansComponent = loansTabs;
        }

        return clientLoansComponent
    }
}

export default ClientLoans;