import React, { useEffect, useState } from "react";
import ApiLoan from "../../service/ApiLoanService";
import {Fieldset} from "primereact/fieldset";
import MoneyField from "../../UI/MoneyField";


const InputInstallmentTotalPaid = props => {
    const [loading, setLoading] = useState(true);
    const [installmentId, setInstallmentId] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (props.installment_id !== ''){
            setInstallmentId(props.installment_id);
            setLoading(false);
        }
    }, [props.installment_id]);

    useEffect(() => {
        getInstallmentTotalPaid();
    }, [installmentId, loading]);

    const getInstallmentTotalPaid = () => {
        if (loading){
            return null;
        }
        let apiService = new ApiLoan();

        let url = '/installment/total_paid/';
        apiService.getResource({
            url: url,
            resource_id: props.installment_id
        }).then(response => {
            let payments = response.data.objects[0].payments;

            let detail = (
                <Fieldset legend="Detalle">
                    <p>Total Pagado: <MoneyField>{payments}</MoneyField></p>
                    <p>Se va a revertir el total pagado</p>
                </Fieldset>
            );

            props.onChange({value: payments});
            setResult(detail);
        });
    }

    if (!loading && result !== null) {
        return (result);
    } else {
        return null;
    }

};

export default InputInstallmentTotalPaid;