import React from 'react'
import Resource from '../../Resources/Resource'

const QuestionnaireView = (props) => {

    const resource = {
        title: 'Cuestionario',
        id: props.match.params.id,
        url: '/questionnaires/',
        fields: [
            { name:'name', traduction:'Identificador'},
            { name:'question', traduction:'Pregunta'},
            { name:'error_message', traduction:'Mensaje de error'},
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.id} fields={resource.fields}/>
    );

}

export default QuestionnaireView;
