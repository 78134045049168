import React from 'react';

import ModalForm from '../../../UI/ModalForm';
import ShortUrlView from './ShortUrlView';

const ModalShortUrl = ({client_id}) => {
    return (<ModalForm title={'Link Corto'}
                       width={'35vw'}
                       buttonProps={{size: 'xs', className: 'btn-actions btn-actions-default btn-fontawesome', icon: 'link'}}>
        <ShortUrlView client_id={client_id}/>
    </ModalForm>);
};

export default ModalShortUrl;