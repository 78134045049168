import React from 'react'
import Resource from '../../Resources/Resource'

const userView = (props) => {

    const resource = {
        title: 'Usuario',
        user_id: props.match.params.id,
        url: '/users/',
        fields: [
            { name:'name', traduction:'Nombre Completo'},
            { name:'username', traduction:'Usuario'},
            { name:'email', traduction:'Email'},
            {
                name: 'role',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Rol'}
                ],
            }
        ]
    }

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.user_id} fields={resource.fields} />
        </div>
    );

}

export default userView;