export const roles = {
    audience: {
        exclude_fields: ['provider_id']
    },
    provider: {
        exclude_fields: ['provider_id']
    },
    collector: {
        exclude_fields: ['provider_id']
    },
    supervisor: {
        exclude_fields: ['provider_id']
    },
}