import React from 'react'
import Resource from '../../Resources/Resource'

const defaultStatusView = (props) => {

    const resource = {
        title: 'Estado de Deuda',
        default_status_id: props.match.params.id,
        url: '/default_statuses/',
        fields: [
            { name:'description', traduction:'Descripcion'},
            { name:'slug', traduction:'Slug'},
            { name:'severity',traduction:'Gravedad'},
            { name:'from_day', traduction:'Desde'},
            { name:'to_day', traduction:'Hasta'},
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.default_status_id} fields={resource.fields}/>
    );

}

export default defaultStatusView;