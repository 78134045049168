import React from 'react';
import Resources from '../Resources/Resources';
import {switchRole} from "../../shared/roles";


const notifications = () => {

    const exclude_columns = {
        'audience': ['default_status.description']
    }
    const role = switchRole()

    let resource = {
        title: 'Notificaciones',
        url: '/notifications',
        colsHeader: [
            { field: 'channel.description',
                header: 'Canal',
                filter: true,
                filter_dropdown: {
                    url: '/channels/available',
                    value: 'description',
                    label: 'description',
                    default: 'Todos'
                },
                relation: 'channel__description'
            },
            { field: 'title', header: 'Título', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'body', header: 'Mensaje', truncate: true },
            {
				field: "custom_tags__name",
				header: "Tags",
				filter: true,
				multiselect_dropdown: {
					params: {
						url: "/custom_tags",
						filters: [
							{
								name: "active",
								op: "equals",
								val: true
							}
						],
                        page: 1,
					},

					value: "name",
					label: "name",
                    selectedItemsLabel: '{0} tags seleccionados',
				},
				relation: "custom_tags__name",
				parent_field: "custom_tags"
			},
            { field: 'enabled', header: 'Habilitado', enabled: true },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/notification/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/notifications',
            '/notifications/new',
            '^\\/notification\\/\\d+$',
            '^\\/notification\\/\\d+\\/edit$'
        ]
    };

    let {colsHeader} = resource;

    if(exclude_columns[role]){
        colsHeader = colsHeader.filter(col => {
            return !exclude_columns[role].includes(col.field)
        });
    }

    let updatedResource = {...resource, colsHeader: colsHeader};

    return (<Resources title={updatedResource.title} url={updatedResource.url} colsHeader={updatedResource.colsHeader}
                       action={updatedResource.action} urlTable={updatedResource.urlTable} />);
};

export default notifications;

