import React from 'react'
import Resource from '../../Resources/Resource'

const LoanView = (props) => {

    const resource = {
        title: 'Operación',
        loan_id: props.match.params.id,
        url: '/loans/',
        fields: [
            { name:'amount', traduction:'Monto Total', format:'money'},
            { name:'amount_borrowed', traduction:'Monto Prestado', format:'money'},
            { name:'interest',traduction:'Interés', format:'money'},
            { name:'cbu', traduction:'CBU'},
            { name:'bank', traduction:'Banco'},
            { name:'requested_installment', traduction:'Cantidad de Cuotas'},
            { name:'remote_loan_id', traduction:'ID Remoto'},
            { name:'created', traduction:'Creado', format:'date'},
            {
                name: 'default_status',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Estado de Mora'}
                ],
            },
            {
                name: 'balance',
                traduction:'Balance',
                component: {
                    name: 'Balance',
                    props: {
                            colsHeader: [
                                { field: 'number', header: 'Nº Referencia'},
                                { field: 'due_date', header: 'Fecha Vencimiento'},
                                { field: 'credit', header: 'Debe'},
                                { field: 'debit', header: 'Haber'},
                                { field: 'balance', header: 'Balance'}
                            ],
                            url: '/loans/' + props.match.params.id + '/balance',
                            title: 'Balance de la Operación ' + props.index,
                    }
                }
            }
        ]
    };

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.loan_id} fields={resource.fields} />
        </div>
    );

};

export default LoanView;