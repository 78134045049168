import React, { useEffect, useState } from "react";
import ApiLoan from "../../service/ApiLoanService";
import DateField from "../../UI/DateField";
import {Fieldset} from "primereact/fieldset";
import MoneyField from "../../UI/MoneyField";


const InputInstallmentTotalDebt = props => {
    const [loading, setLoading] = useState(true);
    const [installmentId, setInstallmentId] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (props.installment_id !== ''){
            setInstallmentId(props.installment_id);
            setLoading(false);
        }
    }, [props.installment_id]);

    useEffect(() => {
        getInstallmentTotalDebt();
    }, [installmentId, loading]);

    const getInstallmentTotalDebt = () => {
        if (loading){
            return null;
        }
        let apiService = new ApiLoan();

        let url = '/installment/total_debt/';
        apiService.getResource({
            url: url,
            resource_id: props.installment_id
        }).then(response => {
            let amount = response.data.objects[0].amount;
            let interests = response.data.objects[0].interests;
            let payments = response.data.objects[0].payments;
            let due_date = response.data.objects[0].due_date;
            let total_debt = response.data.objects[0].total_debt;

            let detail = (
                <Fieldset legend="Detalle">
                    <p>Valor Cuota: <MoneyField>{amount}</MoneyField></p>
                    <p>Intereses: <MoneyField>{interests}</MoneyField></p>
                    <p>Pagos parciales: <MoneyField>{payments}</MoneyField></p>
                    <p>Fecha de Vencimiento: <DateField>{due_date}</DateField></p>
                    <p>Deuda Total: <MoneyField>{total_debt}</MoneyField></p>
                </Fieldset>
            );

            props.onChange({value: total_debt});
            setResult(detail);
        });
    }


    if (!loading && result !== null) {
        return (result);
    } else {
        return null;
    }

};

export default InputInstallmentTotalDebt;