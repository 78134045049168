import React from 'react';
import Resources from '../Resources/Resources';

const loans = () => {
    const resource = {
        title: 'Operaciones',
        url: '/loans',
        colsHeader: [
            { field: 'amount', header: 'Monto Total', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'amount_borrowed', header: 'Monto Prestado', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'requested_installment', header: 'Cuotas', sortable: true },
            { field: 'default_status.description', header: 'Tipo de Mora', sortable: true, filter: true, filterMatchMode: 'contains', relation: 'default_status__description' },
            { field: 'remote_loan_id', header: 'ID Remoto', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'created', header: 'Creado', sortable: true },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/loan/'
        }

    };

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action} />);
};

export default loans;


