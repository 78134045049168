import React from 'react'
import Rate from "./Rate";

const rateEdit = (props) => {

    const resource = {
        title: 'Editar Tarifa',
        url: '/rates/',
        rate_id: props.match.params.id,
        fields: [
            {
                price: {
                    elementType: 'text',
                    elementConfig: {
                        keyfilter: 'money'
                    },
                    value: '',
                    traduction: 'Precio'
                }
            }
        ],
        action: 'edit'
    }

    return (
        <Rate title={resource.title} url={resource.url} fields={resource.fields}
                  resource_id={resource.rate_id} action={resource.action}/>
    );
}

export default rateEdit;