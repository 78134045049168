import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import LoanView from './LoanView';

const LoansAccordionList = ({value}) => {
    const loan_list = value ? value : []
    const accordion_list = loan_list.map((loan, index) => {
        return (<Accordion key={index}>
                    <AccordionTab header={'Operación ' + (index + 1)}>
                        <LoanView match={{params:{id: loan.id}}} index={index + 1}/>
                    </AccordionTab>
                </Accordion>)
    });
    return (
        <React.Fragment>
            {accordion_list}
        </React.Fragment>
    );

};

export default LoansAccordionList;