import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import TransactionAmountDigits from './TransactionAmountDigits';

import ApiLoan from "../../service/ApiLoanService";

const TransactionAmounts = () => {

    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState({
        amount: '_',
        available_amount: '_' 
    });

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/my_account'
        }).then(response => {
            const formattedAccount = {
                amount: response.data.amount,
                available_amount: response.data.available_amount
            };
            setAccount(formattedAccount);
            setLoading(false);
        });
    } ,[]);

    let form = null;
    if (!loading){
        form = (
            <div className="overview-box-actions">
                <div className="overview-box overview-box-4 overview-amount-box">
                    <div className="overview-box-content">
                        <div className="overview-box-amounts">
                            <div className="total-amount-box">
                                <div className="total-amount-title">Saldo</div>
                                <TransactionAmountDigits amount={account.amount}/>
                            </div>
                            <div className="total-available-amount-box">
                                <div className="total-amount-title">Disponible</div>
                                <TransactionAmountDigits amount={account.available_amount}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'withdrawal'}>
                    <div className="overview-box overview-box-4 actions-amount-box">
                        <div className="overview-box-content">
                            <div className="overview-box-actions"> Retirar Dinero </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    return form;
};

export default TransactionAmounts;