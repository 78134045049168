import ApiLoan from '../service/ApiLoanService';

export const getProviderInboxSetting = async () => {
    const apiService = new ApiLoan();
    return apiService.getResources({
        url: '/profile'
    }).then(response => {
        localStorage.setItem('privateInbox', response.data.provider.private_inbox);
        localStorage.setItem('privateGroup', response.data.provider.private_group);
        localStorage.setItem('username', response.data.username);
        if (response.data.provider.gateway !== null){
            localStorage.setItem('gatewayWaba', response.data.provider.gateway);
        }
        return response.data.provider;
    });
}
