import React  from 'react';
import Resources from '../Resources/Resources';

const AutoReplies = (props) => {

    const autoReplyEditButton = (e) => {
        props.history.push('/auto_replies/edit')
    }

    const resource = {
        title: 'Respuestas Automáticas',
        url: '/auto_replies',
        colsHeader: [
            { field: 'day', header: 'Día', daysWeek: true},
            { field: 'start', header: 'Desde'},
            { field: 'end', header: 'Hasta'},
            { field: 'template.title', header: 'Plantilla', relation: 'templates__title'},
            { field: 'active', header: 'Activo', checkbox: true},

        ],
        action: {
            addGenericButton: {
                button: false,
                eventButton: autoReplyEditButton,
                label: 'Editar',
                icon: 'pi pi-pencil',
            }
        },
        urlTable: [
            '/auto-replies',
        ]
    }


    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default AutoReplies;
