import React, {Fragment, useEffect, useState, useRef} from "react";
import TransactionLogs from "../Logs/TransactionLogs";
import ApiLoan from "../../service/ApiLoanService";
import { Toast } from 'primereact/toast';

const TransactionLogsDebit = props => {
    const [button, setButton] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (props.location.state !== null && props.location.state !== undefined){
            if (props.location.state.update) {
                setCount(count+1);
            }
        }
    }, [props.location]);

    const buttonOnClick = (e) => {
        axiosAsync().catch((error) => {
            showError(error.response.data.message);
        });
    }

    const revertUrl = '/coelsa/revert';

    const toast = useRef(null);

    const showSuccess = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Archivos generados de débito';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Archivos generados de débito';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }

    const axiosAsync = async() => {
        let apiService = new ApiLoan();

        let response = await apiService.postResource({
            url: '/coelsa/create/debit/file'
        });
        if (response.status === 201){
            setCount(count+1);
            showSuccess('El archivo se ha generado');
        } else {
            showError(response.data.message);
        }
    };

    return (
        <Fragment>
            <Toast ref={toast} />
            <TransactionLogs revertUrl={revertUrl} title={'Archivos Generados de Débitos'} key={count}
                             name={"description"} op={"eq"} val={"Created Coelsa File."} addGenericButton={true}
                             labelButton={"Generar Archivo"} button={button} setButton={buttonOnClick}/>
        </Fragment>
    );

}

export default TransactionLogsDebit;