import React from 'react';

const DateTimeField = ({children}) => {
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: 'America/Argentina/Buenos_Aires'
    };

    return (
        <React.Fragment>
            {new Intl.DateTimeFormat('es-ES',options).format(new Date(children))}
        </React.Fragment>
    );
};

export default DateTimeField;