import React, { Component } from 'react';

import ApiLoan from '../../service/ApiLoanService';
import Balance from './Balance';


class Balances extends Component {

    state = {
        loans: this.props.loans,
        loadedLoans: 0,
        loading: true
    };

    apiService = new ApiLoan();

    componentDidMount(){
        let {loans} = this.state
        for (let loan in loans){
            this.apiService.getResource({
                url: '/loans/' + loans[loan]['id'] + '/balance'
            }).then(response => {
                let {loadedLoans} = this.state
                const operations = response.data;
                loans[loan]['operations'] = operations
                this.setState({
                    loans: loans,
                    loadedLoans: loadedLoans + 1,
                    loading: !(loans.length === (loadedLoans + 1))
                });
            });
        }
    }

    render(){
        const loans = this.state.loans.filter((loan) => {
            return loan.id === this.props.selectedLoan ? loan : null;
        })

        let balancesTabsView = null;
        if(!this.state.loading){
            const balancesTabs = loans.map((loan, index) => {
            return (<Balance
                        key={index}
                        colsHeader={[
                                        { field: 'number', header: 'Nº Referencia'},
                                        { field: 'due_date', header: "Fecha Vencimiento"},
                                        { field: 'credit', header: 'Debe'},
                                        { field: 'debit', header: 'Haber'},
                                        { field: 'balance', header: 'Balance'}
                                    ]} 
                        operations={loan.operations || []}/>);
            });
            balancesTabsView = balancesTabs;

        }
        

        return balancesTabsView
    }
}

export default Balances;