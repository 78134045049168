import React from 'react'
import Resource from '../Resources/Resource'

const ChatBotView = (props) => {

    const resource = {
        title: 'Bot',
        id: props.match.params.id,
        url: '/chat_bots/',
        fields: [
            { name:'day', traduction:'Día', daysWeek: true},
            { name:'start', traduction:'Desde'},
            { name:'end', traduction:'Hasta'},
            { name:'active', traduction:'Habilitado', checkbox: true},
            { name:'trace', traduction:'Rastro', checkbox: true},
            { name:'active_bot_response', traduction:'Respuesta del bot', checkbox: true},
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.id} fields={resource.fields}/>
    );

}

export default ChatBotView;
