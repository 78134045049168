import React from 'react';

import Resources from '../Resources/Resources';


const CustomTags = () => {
    const resource = {
        title: 'Lista de Tags',
        url: '/custom_tags',
        colsHeader: [
            { field: 'slug', header: 'Slug', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'name', header: 'Nombre', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'description', header: 'Descripción', truncate: true },
            { field: 'active', header: 'Activo', checkbox: true},
            { field: 'fixed', header: 'Fijo', checkbox: true},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/custom_tags/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/custom_tags',
            '/custom_tags/new',
            '^\\/custom_tags\\/\\d+$',
            '^\\/custom_tags\\/\\d+\\/edit$',
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       customTableHeaderButtons={resource.customTableHeaderButtons} customActions={resource.customActions}
                       urlTable={resource.urlTable} />);
}

export default CustomTags;