import React from 'react';
import Resources from '../Resources/Resources';

const transactionLogs = (params) => {
    let resource = {
        title: params.title,
        url: '/coelsa/transaction_logs',
        colsHeader: [
            { field: 'created', header: 'Fecha', date: true},
            { field: 'additional_information', header: 'Resultado', format: 'children'},
            { field: 'action', header: 'Acciones' }
        ],
        params: {
            filters: [
                {
                    name: params.name,
                    op: params.op,
                    val: params.val
                }
            ],
            order_by: {
                field: 'created',
                direction: 'desc'
            }
        },
        action: {
        },
        customActions: [
            { field: 'get_csv', uri: '/coelsa/files/' }
        ]
    };

    if(params.revertUrl){
        resource.customActions.push({ field: 'revert_coelsa', uri: params.revertUrl});
    }
    if (params.addGenericButton){
        resource.action['addGenericButton'] = {
            button: params.button,
            eventButton: params.setButton,
            label: params.labelButton
        }
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       params={resource.params} action={resource.action}
                       customActions={resource.customActions} />);
};

export default transactionLogs;