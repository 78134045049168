export const ADMIN = '1';
export const PROVIDER = '2';
export const CLIENT = '3';
export const AUDIENCE = '4';
export const COLLECTOR = '5';
export const MANAGER = '6';
export const PAYMENT_ORDER_CREATOR = '7';
export const TEXTER = '8';
export const SUPERVISOR = '9';
export const CUSTOMER_SUPPORT = '10';

export const switchRole = () => {
    let role;

    switch(localStorage.getItem('role')) {
        case ADMIN:
            role = 'admin';
            break;
        case PROVIDER:
            role = 'provider';
            break;
        case CLIENT:
            role = 'client';
            break;
        case AUDIENCE:
            role = 'audience';
            break;
        case COLLECTOR:
            role = 'collector';
            break;
        case MANAGER:
            role = 'manager';
            break;
        case PAYMENT_ORDER_CREATOR:
            role = 'payment_order_creator';
            break;
        case TEXTER:
            role = 'texter';
            break;
        case SUPERVISOR:
            role = 'supervisor';
            break;
        case CUSTOMER_SUPPORT:
            role = 'customer_support';
            break;
        default:
            role = null;
            break;
    }
    return role;
};